import React from "react"
import Fade from "react-reveal/Fade"
import data from "../yourdata"

const About = () => {
  return (
    <div className="secion" id="about">
      <div className="container">
        <div className="about-section">
          <div className="content">
            <Fade bottom cascade>
              <h1>About Me</h1>
            </Fade>
            <p>
              {data.aboutParaOne}
              <br></br>
              <br></br>
              {/* {data.aboutParaTwo} */}
              <br></br>
              <br></br>
              {data.aboutParaThree}
              <br></br>

              <ul>
                <li>Full-Stack Development</li>
                <li>Front-End Development</li>
                <li>Back-End Development</li>
                <li>Mobile Apps Development </li>
              </ul>
            </p>
          </div>
          <div className="image-wrapper">
            <img
              src={data.aboutImage}
              alt="about"
              style={{ width: "330px" }}
            ></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
