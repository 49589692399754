// Skills Icons
import htmlIcon from "./images/html.svg"
import cssIcon from "./images/css.svg"
import reactIcon from "./images/react.svg"
import jsIcon from "./images/javascript.svg"
import designIcon from "./images/design.svg"
import codeIcon from "./images/code.svg"
import linkedinIcon from "./images/linkedinIcon.png"

// Social Icon
import githubIcon from "./images/github.svg"
import instagramIcon from "./images/instagram.svg"

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "Yazan",
  headerTagline: [
    //Line 1 For Header
    "I am a Software ",
    //Line 2 For Header
    "Engineer",
    //Line 3 For Header
    "based in NYC ",
  ],
  //   Header Paragraph
  headerParagraph:
    "In this website I link some of my favorite projects and a direct link to my LinkedIn that shows my current and past roles I've had, and I talk a little about my expertise.",

  //Contact Email
  contactEmail: "me@yazanalmatar.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "ProjectFrame.io", //Project Title - Add Your Project Title Here
      para: "Tasks based project management web-based app", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://url-images-yazan.s3.us-east-2.amazonaws.com/projectframe.png",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/YazanAlmatar99/ProjectFrame.tech/",
    },
    {
      title: "Cocell.io", //Project Title - Add Your Project Title Here
      para:
        "Interactive online JavaScript IDE that you can use to build and test front-end code", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://url-images-yazan.s3.us-east-2.amazonaws.com/cocell.png",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/YazanAlmatar99/cocell/",
    },
    {
      title: "HotSpot", //Project Title - Add Your Project Title Here
      para:
        "Another way of meeting friends and setting up local events with chatting capabilities!", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
        "https://url-images-yazan.s3.us-east-2.amazonaws.com/hotspot.png",
      //Project URL - Add Your Project Url Here
      url: "https://github.com/kingMelky23/Hot-Spot",
    },
    // {
    //   title: "Project Four", //Project Title - Add Your Project Title Here
    //   para:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
    //   //Project Image - Add Your Project Image Here
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1492171983775-a51717616c0d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjB8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
    //   //Project URL - Add Your Project Url Here
    //   url: "http://chetanverma.com/",
    // },
    // {
    //   title: "Project Five", //Project Title - Add Your Project Title Here
    //   para:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
    //   //Project Image - Add Your Project Image Here
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1534239143101-1b1c627395c5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NzZ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
    //   //Project URL - Add Your Project Url Here
    //   url: "http://chetanverma.com/",
    // },
    // {
    //   title: "Project Six", //Project Title - Add Your Project Title Here
    //   para:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
    //   //Project Image - Add Your Project Image Here
    //   imageSrc:
    //     "https://images.unsplash.com/photo-1517479149777-5f3b1511d5ad?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTAyfHxwYXN0ZWx8ZW58MHx8MHw%3D&auto=format&fit=crop&w=400&q=60",
    //   //Project URL - Add Your Project Url Here
    //   url: "http://chetanverma.com/",
    // },

    /*
    If You Want To Add More Project just Copy and Paste This At The End
    ,{
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "Ever since I was a kid, software development has been my passion, I was always curious about how everything works and connected together. I started learning my first programming language (Visual Basic) on my own when I was 14 years old, and I kept developing a passion for the industry, then my passion carried me away to get into college to study computer science and get professional academic learning.",
  aboutParaTwo:
    "And now I keep learning on my own and also in college, I'm never satisfied and I will never stop exploring and learning new technologies everyday, I always try to adapt on-demand technologies to stay up to date with the industry.",
  aboutParaThree: "I specialize in web-based technologies including:",
  aboutImage: "https://url-images-yazan.s3.us-east-2.amazonaws.com/mypic.jpg",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      img: htmlIcon,
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    },
    {
      img: cssIcon,
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    },
    {
      img: jsIcon,
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    },
    {
      img: reactIcon,
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    },
    {
      img: designIcon,
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    },
    {
      img: codeIcon,
      para:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
    },
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  promotionHeading: "Heading",
  promotionPara:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Get in touch!",
  social: [
    // Add Or Remove The Link Accordingly
    { img: githubIcon, url: "https://github.com/YazanAlmatar99" },
    {
      img: linkedinIcon,
      url: "https://www.linkedin.com/in/yazanalmatar/",
    },
    {
      img: instagramIcon,
      url: "https://www.instagram.com/zane_alm/",
    },
  ],

  // End Contact Section ---------------
}

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
